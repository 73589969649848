import styled from 'styled-components'

const AllText = `
  color: white;
  padding: 0;
  margin: 0;
`

export const Title1 = styled.h1`
  ${AllText}
  font-size: 3.75rem;
  font-weight: 500;

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    font-size: 8vmin;
  }
`

export const Title2 = styled.h2`
  ${AllText}
  font-size: 3.4375rem;
  font-weight: 500;

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    font-size: 7vmin;
  }
`

export const Title3 = styled.h3`
  ${AllText}
  font-size: 2.5rem;
  font-weight: 500;

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    font-size: 6vmin;
  }
`

export const Title4 = styled.h4`
  ${AllText}
  font-size: 2rem;
  font-weight: normal;

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    font-size: 5vmin;
  }
`

export const Title5 = styled.h5`
  ${AllText}
  font-size: 1.5rem;
  font-weight: normal;

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    font-size: 4vmin;
  }
`

export const Title6 = styled.h6`
  ${AllText}
  font-size: 1.25rem;
  font-weight: normal;

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    font-size: 3vmin;
  }
`

export const Text = styled.p`
  font-size: 1.5rem;
  line-height: 2.75rem;
  > span.bold {
    font-weight: bold;
  }
  > svg {
    vertical-align: middle;
  }
`

export const SmallText = styled.span`
  color: white;
  font-size: 1.125rem;
  line-height: 1.8rem;
  font-weight: lighter;
`

export const Quote = styled.p`
  font-family: 'Noto Serif', serif;
  font-size: 1.5rem;
  line-height: 2;
`

export const Bold = styled.span`
  font-weight: bold;
`
