import Color from 'color'
import styled, { css, keyframes } from 'styled-components'

export const popInFrames = keyframes`
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

export const squeezeFrames = keyframes`
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(1.05, 0.95);
  }
  30% {
    transform: scale(0.95, 1.05);
  }
  50% {
    transform: scale(1.025, 0.975);
  }
  100% {
    transform: scale(1, 1);
  }
`

export const swayFrames = keyframes`
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-1rem, -1rem);
  }
  100% {
    transform: translate(0, 0);
  }
`

export const slideInTopLeftFrames = keyframes`
  25% {
    opacity: 0;
  }
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
`

export const fadeInFrames = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const resetFadeFrames = keyframes`
  30% {
    opacity: 0;
  }
  100% {
    transform: rotate(0) translateX(0) translateY(0) scale(1);
    opacity: 1;
  }
`

export const resetFrames = keyframes`
  100% {
    transform: rotate(0) translateX(0) translateY(0) scale(1);
    opacity: 1;
  }
`

export const bruxBezierTimingFunction = css`
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  transition-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
`

export const bruxBezierPopTimingFunction = css`
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
`

export const squeezeChildrenAnimation = css`
  animation: ${squeezeFrames} 2s none 1.5s 1 running;
  ${bruxBezierTimingFunction}

  &:first-of-type {
    animation-delay: 0s;
  }
  &:nth-of-type(2) {
    animation-delay: 0.1s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.2s;
  }
  &:nth-of-type(4) {
    animation-delay: 0.3s;
  }
`

export const fadeInQuicklyAnimation = css`
  animation: ${fadeInFrames};
  animation-duration: 1.5s;
  ${bruxBezierTimingFunction}
  animation-play-state: running;
  animation-fill-mode: backwards;
`

export const fadeInSlowlyAnimation = css`
  animation: ${fadeInFrames};
  animation-duration: 3s;
  ${bruxBezierTimingFunction};
  animation-play-state: running;
  animation-fill-mode: backwards;
`

export const popInQuicklyAnimation = css`
  animation: ${popInFrames};
  animation-duration: 0.35s;
  ${bruxBezierPopTimingFunction}
  animation-fill-mode: backwards;
`

export const resetPositionFadeInAnimation = css`
  animation: ${resetFadeFrames};
  animation-duration: 1s;
  ${bruxBezierTimingFunction};
  animation-play-state: running;
  animation-fill-mode: forwards;
`

export const resetPositionAnimation = css`
  animation: ${resetFrames};
  animation-duration: 0.5s;
  ${bruxBezierTimingFunction};
  animation-play-state: running;
  animation-fill-mode: forwards;
`

export const Slider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100%);
  transition: transform 0.2s;
  transition-timing-function: ${bruxBezierTimingFunction};
  background-color: ${({ theme: { blue } }) =>
    Color(blue)
      .alpha(0.5)
      .toString()};
`
