import useEventListener from '@use-it/event-listener'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { squeezeChildrenAnimation } from '../styles/animations'

interface Props {
  animated?: boolean
  className?: string
}

const Logo = styled.svg`
  #tower-color-shifted {
    opacity: 0;
  }
  .animated {
    #tower-color-shifted {
      animation-name: fade-in-out;
      animation-duration: 5s;
      animation-delay: 4s;
      animation-play-state: paused;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    }
    @keyframes fade-in-out {
      0% {
        opacity: 0;
      }
      30% {
        opacity: 1;
      }
      60% {
        opacity: 1;
      }
      90% {
        opacity: 0;
      }
    }
    #circle {
      transform-origin: 52.5% 65%;
      animation-name: rotate;
      animation-duration: 5s;
      animation-delay: 4s;
      animation-play-state: paused;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    }
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      30% {
        transform: rotate(180deg);
      }
      60% {
        transform: rotate(180deg);
      }
      90% {
        transform: rotate(360deg);
      }
    }
  }
`

const Text = styled.div`
  font-weight: 500;
  color: white;
  font-size: 3rem;
  display: flex;
  align-items: flex-end;
  letter-spacing: 3px;
  margin-bottom: -7px;
`

const Container = styled.div<Pick<Props, 'animated'>>`
  display: flex;
  > svg {
    height: 100%;
    width: auto;
    transform: translateX(-13px);
  }

  :hover {
    ${Text} {
      ${({ animated }) => animated && squeezeChildrenAnimation}
    }
  }
`

const Brand: FunctionComponent<Props> = ({
  className = '',
  animated = false
}) => {
  useEventListener<MouseEvent>('mouseenter', () => console.log('entered'))
  useEventListener<MouseEvent>('mouseleave', () => console.log('left'))
  return (
    <Container {...{ className, animated }}>
      <Logo width="400" height="400" viewBox="0, 0, 400,400">
        <linearGradient id="tower-colors" gradientTransform="rotate(90)">
          <stop offset="65%" stopColor="#06cbfa" />
          <stop offset="35%" stopColor="#333a9a" />
        </linearGradient>

        <linearGradient
          id="tower-colors-shifted"
          gradientTransform="rotate(90)"
        >
          <stop offset="65%" stopColor="#06cbfa" />
          <stop offset="35%" stopColor="#e393e2" />
        </linearGradient>

        <path
          id="tower"
          d="M77 74 L77 393 L210 393 L210 74 A50,50 0 0,0 77,74z"
          fill="url('#tower-colors')"
        />
        <path
          id="tower-color-shifted"
          d="M77 74 L77 393 L210 393 L210 74 A50,50 0 0,0 77,74z"
          fill="url('#tower-colors-shifted')"
        />

        <linearGradient id="circle-colors">
          <stop offset="50%" stopColor="#333a9a" />
          <stop offset="50%" stopColor="#e393e2" />
        </linearGradient>

        <circle
          id="circle"
          cx="210"
          cy="260"
          r="133"
          fill="url('#circle-colors')"
        />
      </Logo>
      <Text>b</Text>
      <Text>r</Text>
      <Text>u</Text>
      <Text>x</Text>
    </Container>
  )
}

export default Brand
