import React, { FunctionComponent, ReactChild, ReactElement } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { Slider } from '../styles/animations'

interface Props {
  onClick?: () => void
  link?: string
  color?: string
  className?: string
  children: string | Array<string | ReactChild>
}

const ButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  display: block;
  text-align: center;
  font-size: 1.125rem;
  border: none;
  padding: 1rem;
  border-radius: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
    /* > ${Slider} {
      transform: translateX(0);
    } */
  }
`

const StyledLink = styled(Link)`
  ${ButtonStyle}

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    font-size: 3vmin;
  }
`

const StyledButton = styled.button`
  ${ButtonStyle}

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    font-size: 3vmin;
  }
`

const Button: FunctionComponent<Props> = ({
  onClick,
  link,
  color = 'pink',
  className = '',
  children,
}) =>
  link ? (
    <StyledLink to={link} onClick={onClick} className={`${className} ${color}`}>
      {/* <Slider /> */}
      {children}
    </StyledLink>
  ) : (
    <StyledButton onClick={onClick} className={`${className} ${color}`}>
      {/* <Slider /> */}
      {children}
    </StyledButton>
  )

export default Button
