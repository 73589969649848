import React, { forwardRef } from 'react'
import { ThemeProvider } from 'styled-components'

const Layout = forwardRef(({ children }, ref) => (
  <ThemeProvider
    theme={require('sass-extract-loader?{"plugins": ["sass-extract-js"]}!../styles/theme.scss')}
  >
    {children}
  </ThemeProvider>
))

export default Layout
