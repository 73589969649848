import React, { FunctionComponent, ReactElement } from 'react'
import styled from 'styled-components'

interface Props {
  color?: string
  className?: string
  children?: ReactElement | ReactElement[]
}

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Shape: FunctionComponent<Props> = ({
  color = 'darkPurple',
  className = '',
  children
}) => <StyledDiv className={`${className} ${color}`}>{children}</StyledDiv>

export default Shape

interface ShapeProps {
  diameter: number
}

export const QuarterCircle = styled(Shape)<ShapeProps>`
  display: inline-block;
  width: ${({ diameter }) => diameter}rem;
  height: ${({ diameter }) => diameter}rem;
  border-top-right-radius: ${({ diameter }) => diameter * 2}rem;
`
export const Circle = styled(Shape)<ShapeProps>`
  width: ${({ diameter }) => diameter}rem;
  height: ${({ diameter }) => diameter}rem;
  border-radius: ${({ diameter }) => diameter}rem;
`

export const BottomSemiCircle = styled(Shape)<ShapeProps>`
  width: ${({ diameter }) => diameter}rem;
  height: ${({ diameter }) => diameter / 2}rem;
  border-bottom-left-radius: ${({ diameter }) => diameter * 2}rem;
  border-bottom-right-radius: ${({ diameter }) => diameter * 2}rem;
`

export const TopLeftSemiCircle = styled(Shape)<ShapeProps>`
  width: ${({ diameter }) => diameter}rem;
  height: ${({ diameter }) => diameter / 2}rem;
  border-top-right-radius: ${({ diameter }) => diameter}rem;
`
