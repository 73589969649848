import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { QuarterCircle } from './shape'
import { Title2 } from './typography'

interface Props {
  children: string
}

const Container = styled.div`
  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    margin-left: 3.6875rem;
  }
`

const StyledQuarterCircle = styled(QuarterCircle)`
  margin-left: -3.6875rem;
  margin-right: 1.3125rem;
  margin-bottom: 2rem;
`

const StyledTitle2 = styled(Title2)`
  padding-right: 2rem;
  display: inline-block;
`

const Title: FunctionComponent<Props> = ({ children }) => (
  <Container>
    <StyledQuarterCircle color="blue" diameter={2.375} />
    <StyledTitle2>{children}</StyledTitle2>
  </Container>
)

export default Title
