import { Link } from 'gatsby'
import React, { FunctionComponent } from 'react'
import { animated } from 'react-spring'
import styled, { css } from 'styled-components'
import Brand from '../components/brand'
import Shape from '../components/shape'
import { useParallax } from '../hooks/useParallax'
import { bruxBezierTimingFunction, resetFrames } from '../styles/animations'
import {
  getMobileSize,
  getSideMarginMobile,
  getTabletSize
} from '../styles/theme-selectors'

interface Props {
  animated?: boolean
}

const StyledHeader = styled.header`
  height: 12.5rem;
  display: flex;
  justify-content: space-evenly;
  padding: 0 5rem;
  align-items: center;

  @media screen and (max-width: ${getTabletSize}) {
    padding: 0 ${getSideMarginMobile};
  }
`

const StyledBrand = styled(Brand)`
  flex: 0 0 auto;
  height: 4rem;
  align-self: flex-end;
`

const SemiCircleContainer = styled.div`
  flex: 1 1 auto;
  height: 100%;
`

export const rotate90Deg = css`
  opacity: 0;
  transform: rotate(-90deg) translateY(-350px);
  animation: ${resetFrames} 2s none 0.5s running 1;
  animation-fill-mode: forwards;
  ${bruxBezierTimingFunction}
`

const semiCircleRadius = 11

const SemiCircle = styled(Shape)<Props>`
  margin-left: 70%;
  margin-top: -${semiCircleRadius / 2 - 1}rem;
  height: ${semiCircleRadius}rem;
  width: ${semiCircleRadius / 2}rem;
  border-top-right-radius: ${semiCircleRadius}rem;
  border-bottom-right-radius: ${semiCircleRadius}rem;

  @media screen and (max-width: ${getMobileSize}) {
    margin-left: 20vw;
    margin-top: -${semiCircleRadius / 4 - 0.5}rem;
    height: ${semiCircleRadius / 2}rem;
    width: ${semiCircleRadius / 4}rem;
    border-top-right-radius: ${semiCircleRadius / 2}rem;
    border-bottom-right-radius: ${semiCircleRadius / 2}rem;
  }

  /* ${({ animated }) => animated && rotate90Deg} */
`

const AnimatedSemiCircle = animated(SemiCircle)

const Header: FunctionComponent<Props> = ({ animated = false }) => {
  const xy = useParallax()
  return (
    <StyledHeader>
      <Link to="/">
        <StyledBrand animated={animated} />
      </Link>
      <SemiCircleContainer>
        <SemiCircle
          color="blue"
          animated={animated}
          // style={{
          //   transform: xy.interpolate(
          //     (x, y) => `translate3d(${x / 30}px,${y / 30}px,0)`
          //   )
          // }}
        />
      </SemiCircleContainer>
    </StyledHeader>
  )
}

export default Header
