import useEventListener from '@use-it/event-listener'
import { MouseEvent } from 'react'
import { SpringConfig, useSpring } from 'react-spring'

export const useParallax = (springConfig?: SpringConfig) => {
  const [{ xy }, setSpring] = useSpring(() => ({
    xy: [0, 0],
    config: {
      mass: 20,
      tension: 550,
      friction: 200,
      ...springConfig
    }
  }))
  useEventListener<MouseEvent>('mousemove', ({ clientX, clientY }) =>
    setSpring({
      xy: [
        -(clientX - window.innerWidth / 2),
        -(clientY - window.innerHeight / 2)
      ]
    })
  )
  return xy
}
