interface Theme {
  theme: {
    background: string
    backgroundOverlay: string
    pink: string
    darkPurple: string
    blue: string

    tabletSize: string
    mobileSize: string

    sideMarginDesktop: string
    sideMarginMobile: string
  }
}

export const getTabletSize = ({ theme: { tabletSize } }: Theme) => tabletSize
export const getMobileSize = ({ theme: { mobileSize } }: Theme) => mobileSize

export const getSideMarginDesktop = ({ theme: { sideMarginDesktop } }: Theme) =>
  sideMarginDesktop
export const getSideMarginMobile = ({ theme: { sideMarginMobile } }: Theme) =>
  sideMarginMobile

export const getBackground = ({ theme: { background } }: Theme) => background
export const getBackgroundOverlay = ({ theme: { backgroundOverlay } }: Theme) =>
  backgroundOverlay
export const getPink = ({ theme: { pink } }: Theme) => pink
export const getDarkPurple = ({ theme: { darkPurple } }: Theme) => darkPurple
export const getBlue = ({ theme: { blue } }: Theme) => blue
